<template>
  <v-app class="background">
    <v-main>
      <div style="position: absolute; top: 32px; right: 32px;">
        <ProfileAppBar />
      </div>
      <router-view />
      <CookieBar />
    </v-main>
  </v-app>
</template>

<script>
import CookieBar from '@/components/CookieBar';
import ProfileAppBar from '@/components/ProfileAppBar';

export default {
  name: 'GuestLayout',
  components: { CookieBar, ProfileAppBar }
};
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.background {
  background-color: #fff !important;
  background-image: url('../assets/Login_Bg.jpg') !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
</style>
